<template>
  <div>
    <h1>ログインアカウント発行</h1>
    <p>
      発行するとログインID、パスワードに社員番号(6桁)を設定するとユーザログイン可能になります。
    </p>
    <v-card outlined class="mb-5">
      <!-- Expansion -->
      <v-expansion-panels flat>
        <v-expansion-panel>
          <!-- Header -->
          <v-expansion-panel-header>
            <span class="headline">検索条件</span>
          </v-expansion-panel-header>

          <!-- Body -->
          <v-expansion-panel-content>
            <v-text-field
              v-model="filterUserName"
              label="社員名"
              outlined
              clearable
              dense
            ></v-text-field>
            <v-autocomplete
              v-model="selectFilterAuthority"
              :items="filterAuthority"
              label="権限"
              clearable
              dense
              item-text="name"
              item-value="code"
            >
            </v-autocomplete>
            <v-autocomplete
              v-model="selectFilterValid"
              :items="filterValid"
              label="状態"
              clearable
              dense
              item-text="name"
              item-value="code"
            >
            </v-autocomplete>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <!-- Actions -->
      <v-card-actions class="ma-2">
        <v-spacer />
        <Btn color="info" icon="mdi-magnify" @click="select">検索</Btn>
      </v-card-actions>
    </v-card>
    <v-card class="mt-5">
      <v-card-actions class="ma-2">
        <v-autocomplete
          v-model="selectSetAuthority"
          :items="setAuthority"
          label="発行時の設定権限"
          item-text="name"
          item-value="code"
        >
        </v-autocomplete>
        <v-spacer />
        <Btn
          icon="mdi-account-plus"
          color="primary"
          text
          outlined
          :disabled="!isSelected"
          @click="upsert()"
        >
          発行
        </Btn>
        <Btn
          icon="mdi-account-off"
          color="error"
          text
          outlined
          :disabled="!isSelected"
          @click="updateValid()"
        >
          停止
        </Btn>
      </v-card-actions>
    </v-card>
    <!-- Table -->
    <v-card class="mt-5">
      <v-card-title>
        <span class="text-h4">{{ title }}</span>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          prepend-inner-icon="mdi-magnify"
          label="簡易検索"
          outlined
          rounded
          dense
        ></v-text-field>
      </v-card-title>
      <v-data-table
        v-model="selected"
        :headers="headers"
        :items="items"
        :search="search"
        show-select
        :item-key="itemKey"
        multi-sort
      >
        <template v-slot:item.midnight="{ item }"
          ><v-checkbox v-model="item.midnight" readonly />
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>
<script>
import Common from "@/mixins/common";
import ShowDialogs from "@/mixins/showDialogs";
import Api from "@/mixins/api";
import { CODE_GROUP as CodeGroup } from "@/defines";

export default {
  name: "AuthInfoList",
  mixins: [Common, ShowDialogs, Api],
  props: {},
  data() {
    return {
      selected: [],
      search: "",
      title: "テーブル名",
      itemKey: "name",
      headers: [],
      items: [],
      filterAuthority: [],
      selectFilterAuthority: [],
      authorityItems: [],
      filterValid: [],
      selectFilterValid: [],
      filterUserName: "",
      setAuthority: [],
      selectSetAuthority: []
    };
  },
  computed: {
    isSelected() {
      return !!this.selected && this.selected.length > 0;
    }
  },
  methods: {
    async actions(prosess, args) {
      console.log(prosess, args);
    },
    async clickDelete() {
      if (await this.deleteConfirm())
        this.items = this.items.filter(e => !this.selected.includes(e));
    },
    async getdata(path) {
      this.$loading();
      try {
        return await this.$get(path);
      } catch (e) {
        this.$error(e.message);
      } finally {
        this.$unloading();
      }
    },
    async select() {
      console.log("select");

      const param = {
        name: this.filterUserName,
        authority:
          this.selectFilterAuthority.length == 0
            ? ""
            : this.selectFilterAuthority,
        status: this.selectFilterValid.length == 0 ? "" : this.selectFilterValid
      };

      const encode = encodeURI(JSON.stringify(param));
      console.log("encode", encode);
      this.$loading();
      try {
        const data = await this.$get(this.Paths.authinfo, "query=" + encode);
        await this.setdata(data);
      } catch (e) {
        console.log(e);
        this.$error(e.message);
      } finally {
        this.$unloading();
      }
    },
    async upsert() {
      try {
        console.log("upsert");
        if (this.selected.length == 0) {
          this.$warning("社員が選択されていません");
          return;
        }

        const codes = [];
        this.selected.forEach(s => {
          codes.push(s.code);
        });

        const param = {
          codes: codes,
          authority: this.selectSetAuthority
        };

        await this.$post(this.Paths.authinfoBulk, param);

        const data = await this.$get(this.Paths.authinfo);
        await this.setdata(data);

        this.$info("更新しました");
      } catch (e) {
        console.log(e);
        this.$error(e.message);
      } finally {
        this.$unloading();
      }
    },
    async updateValid() {
      try {
        console.log("upsert");
        if (this.selected.length == 0) {
          this.$warning("社員が選択されていません");
          return;
        }

        const codes = [];
        this.selected.forEach(s => {
          codes.push(s.code);
        });

        const param = {
          codes: codes
        };

        await this.$put(this.Paths.authinfoBulk, param);

        const data = await this.$get(this.Paths.authinfo);
        await this.setdata(data);

        this.$info("更新しました");
      } catch (e) {
        console.log(e);
        this.$error(e.message);
      } finally {
        this.$unloading();
      }
    },
    async update() {
      console.log("==update");
      this.$loading();
      try {
        const data = await this.$get(this.Paths.authinfo);

        const authparam = { kbn: CodeGroup.AUTHOR };
        let encode = encodeURI(JSON.stringify(authparam));
        console.log(encode, this.Paths.codeMasterPulldown);
        this.authorityItems = await this.$get(
          this.Paths.codeMasterPulldown,
          "query=" + encode
        );

        this.authorityItems = this.authorityItems.filter(
          e => e.refcode === "0"
        );
        this.filterAuthority = this.authorityItems;
        this.setAuthority = this.authorityItems;
        this.selectSetAuthority = this.setAuthority[0].code;

        const validparam = { kbn: CodeGroup.VALID };
        encode = encodeURI(JSON.stringify(validparam));
        this.filterValid = await this.$get(
          this.Paths.codeMasterPulldown,
          "query=" + encode
        );

        await this.setdata(data);
      } catch (e) {
        console.log(e);
        this.$error(e.message);
      } finally {
        this.$unloading();
      }
    },
    async setdata(data) {
      console.log("setdata");
      this.selected = [];
      this.headers = data.headers;
      this.items = data.items;
      this.title = data.name;
      this.itemKey = data.key;
    }
  },
  created() {
    console.log("==created");
    this.update();
  },
  mounted() {
    console.log("==mounted");
  },
  beforeUpdate() {
    console.log("==beforeUpdate");
  },
  updated() {
    console.log("==updated");
  }
};
</script>
<style></style>
